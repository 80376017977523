@use "./config/" as *;
@forward "./main-scss/";
@forward "./swiper/swiper-bundle";
@forward "./listing/common";
@forward "./listing/home";
.waf-toli-top,
.waf-toli-bottom {
    @extend %p-0;
}
.toli-banner {
    height: 46rem;
    @include background(null, "dc-toli/toli-banner-mob.png", center/cover no-repeat);
    .title {
        @extend %pure-white-900;
        @extend %font-24-pb;
    }
    .toli-wrapper {
        @extend %pt-8;
        @extend %px-3;
        @extend %flex;
    }
    .toli-logo {
        width: 8.6rem;
        flex-shrink: 0;
        img {
            @extend %w-100;
            @extend %h-100;
        }
    }
    .social {
        &-text {
            @extend %font-0;
        }
        &-link {
            @extend %flex-c-c;
            &::before {
                font-family: $font-icon;
                font-size: 1.6rem;
                @extend %neutral-800;
            }
        }
        &-list {
            @extend %gap-3;
            @extend %flex;
        }
        &-item {
            width: 3rem;
            height: 3rem;
            @extend %tertiary-600-bg;
            @extend %circle-radius;
            @extend %flex-c-c;
        }
    }
    .toli-content {
        @extend %pl-4;
        @include flex-config(flex, column, space-between);
    }
}
.toli-image {
    @extend %pt-10;
    @extend %pb-6;
    @extend %px-2;
    .head {
        @extend %pb-6;
        .title {
            @extend %text-center;
            @extend %uppercase;
            @extend %font-32-pb;
            @extend %primary-700;
            @extend %relative;
            &::before {
                content: '';
                height: 4.5rem;
                width: 75%;
                bottom: var(--space-1);
                @extend %pos-x-center;
                @include background(null, "dc-toli/toli-image-title-bg.png", center / contain no-repeat);
            }
        }
    }
    .image {
        &-wrap {
            @extend %relative;
        }
        &-swiper {
            padding-bottom: var(--space-8);
        }
        &-thumbnail {
            cursor: pointer;
            img {
                transition: 300ms transform cubic-bezier(0.175, 0.885, 0.32, 1.66);
                transform: scale(1.1);
                &:hover {
                    transform: scale(1.2);
                }
            }
            .image {
                aspect-ratio: 16/9;
                border-radius: var(--full-radius);
            }
        }
    }
    .swiper-slide {
        @extend %full-radius;
        @extend %hidden;
        &:not(.swiper-slide-active) {
            filter: blur(0.1rem);
            .image-thumbnail {
                &::before {
                    content: "";
                    z-index: 1;
                    @extend %absolute;
                    @extend %pure-white-900-bg-5;
                    @extend %w-100;
                    @extend %h-100;
                }
            }
        }
    }
    .swiper-button {
        background: transparent;
        &-prev {
            bottom: var(--space-1-neg);
            left: calc(50% - var(--swiper-navigation-size) - 8rem);
        }
        &-next {
            bottom: var(--space-1-neg);
            right: calc(50% - var(--swiper-navigation-size) - 8rem);
        }
    }
    .swiper-pagination {
        bottom: var(--space-1);
        justify-content: center;
        align-items: center;
        width: 16rem;
        @include position-combo(x-center);
        &-bullet {
            width: 1.2rem;
            height: 1.2rem;
            border-radius: 50%;
            flex: unset;
            @extend %neutral-500-bg-4;
            @extend %transition;
            &.swiper-pagination-bullet-active {
                height: 2rem;
                width: 2rem;
                @extend %primary-700-bg;
            }
        }
    }
}
.toli-announcement {
    @extend %relative;
    @extend %pt-6;
    &:before {
        content: '';
        z-index: var(--z-patterns);
        @extend %h-100;
        @extend %w-100;
        @extend %pos-tl;
        @include background(null, "pattern/mobile/dc-toli-announcement.png", center / cover no-repeat);
    }
    .head {
        @extend %px-2;
        .title {
            @extend %pure-white-900;
            @extend %capitalize;
            @extend %font-18-pb;
        }
    }
    .body {
        .title {
            height: 15rem;
            isolation: isolate;
            @extend %mb-5;
            @extend %text-center;
            @extend %flex-c-c;
            @extend %pure-white-900;
            @extend %px-6;
            @extend %relative;
            @extend %font-16-pb;
            @extend %capitalize;
            &::before {
                content: '';
                width: calc(100% - 9rem);
                height: 3rem;
                top: -1.8rem;
                pointer-events: none;
                @extend %pos-x-center;
                @include background(null, "pattern/contact-center.png", top/contain no-repeat);
            }
            &:after {
                content: '';
                z-index: var(--z-patterns);
                top: 0;
                border-radius: var(--full-radius) var(--full-radius) 0 0;
                @extend %pos-x-center;
                @extend %w-100;
                @extend %h-100;
                @include background(null, "dc-toli/announcement-title-bg.png", top/100% 100%);
            }
        }
    }
    .announcement {
        &-swiper {
            @extend %px-2;
        }
        &-list {
            padding-block: 7rem;
        }
        &-item {
            border-radius: var(--full-radius);
            width: 85%;
            height: 28rem;
            @extend %mr-4;
            @extend %neutral-5-bg;
            @extend %relative;
            &::before,
            &::after {
                content: '';
                pointer-events: none;
                width: 4rem;
                height: 7rem;
            }
            &::before {
                @include position(-6.9rem, null, null, -.3rem);
                @include background(null, "pattern/contact-left.png", bottom/100% no-repeat);
            }
            &::after {
                @include position(-6.8rem, 0, null, null);
                @include background(null, "pattern/contact-right.png", bottom/100% no-repeat);
            }
        }
        &-content {
            @extend %text-center;
            @extend %px-3;
            @extend %pb-4;
            .text {
                @extend %neutral-800;
                @extend %font-16-pm;
            }
        }
    }
    .swiper-button {
        bottom: 0;
        height: 3.4rem;
        &-next {
            right: 0;
        }
        &-prev {
            right: 4rem;
        }
    }
}
.waf-photos-listing {
    @extend %relative;
    &:before {
        content: '';
        z-index: var(--z-patterns);
        @extend %h-100;
        @extend %w-100;
        @extend %pos-tl;
        @include background(null, "pattern/white-bg.png", top / cover no-repeat);
    }
    .waf-head {
        @extend %relative;
        .primary-tabs {
            top: 6rem;
            @extend %pos-x-center;
        }
    }
    .waf-body {
        @extend %pt-16;
    }
}
.waf-videos-listing {
    .waf-body {
        @extend %mt-4;
    }
}
.toli-vision {
    height: 36rem;
    @extend %pt-8;
    @extend %px-2;
    @extend %relative;
    &:before {
        content: '';
        z-index: var(--z-patterns);
        @extend %h-100;
        @extend %w-100;
        @extend %pos-tl;
        @include background(null, "dc-toli/dc-toli-vision-mob.png", bottom center / cover no-repeat);
    }
    .title {
        @extend %mb-4;
        @extend %text-center;
        @extend %capitalize;
        @extend %primary-700;
        @extend %font-32-pb;
        @extend %relative;
        &::before {
            content: '';
            height: 4.5rem;
            width: 75%;
            bottom: var(--space-1);
            @extend %pos-x-center;
            @include background(null, "dc-toli/vision-title-bg.png", center / contain no-repeat);
        }
    }
    .text {
        @extend %neutral-800;
        @extend %text-center;
        @extend %font-16-pm;
    }
}
@include mq(col-md) {
    .waf-toli-top,
    .waf-toli-bottom {
        .layout-wrapper {
            max-width: 100%;
        }
    }
    .toli-banner {
        @include background(null, "dc-toli/toli-banner.png", center/cover no-repeat);
        .title {
            text-align: center;
            padding-block: var(--space-5);
        }
        .toli-wrapper {
            padding-inline: var(--space-4) 0;
            padding-block: 0;
            height: 100%;
            width: 35%;
            @include flex-config(null, column, center, center);
        }
        .toli-content {
            align-items: center;
            padding-left: 0;
        }
    }
    .toli-vision {
        padding-inline: 0;
        height: 50rem;
        .title {
            font-size: 5.2rem;
            &::before {
                width: 60%;
                bottom: var(--space-5);
                background-size: contain;
                background-position: top;
            }
        }
        .toli-wrapper {
            width: 85%;
            margin-inline: auto;
        }
        .text {
            font-size: 2rem;
        }
    }
    .toli-announcement {
        .head {
            .title {
                font-size: 3.2rem;
            }
        }
    }
}
@include mq(col-lg) {
    .waf-toli-bottom {
        .layout-wrapper {
            max-width: var(--container-max-width);
        }
    }
    .toli-image {
        .swiper-slide {
            border-radius: 3rem;
        }
        .body {
            max-width: var(--container-max-width);
            margin-inline: auto;
            padding-inline: var(--space-12)
        }
    }
    .toli-video {
        height: calc(var(--window-inner-height) - var(--header-height));
        .embed-responsive {
            height: 100%;
        }
    }
    .toli-banner {
        height: calc(var(--window-inner-height) - var(--header-height));
        .title {
            font-size: 3.8rem;
        }
        .social {
            &-link {
                &::before {
                    font-size: 2.2rem;
                }
            }
            &-item {
                width: 5rem;
                height: 5rem;
            }
        }
    }
    .toli-vision {
        padding-top: var(--space-10);
        height: calc(var(--window-inner-height) - var(--header-height));
        &:before {
            left: calc(var(--container-white-space)*-1);
            width: var(--window-inner-width);
            @include background(null, "dc-toli/dc-toli-vision.png", bottom center / cover no-repeat);
        }
        .title {
            &::before {
                height: 5.3rem;
            }
        }
        .toli-wrapper {
            width: 65%;
        }
    }
    .toli-announcement {
        &::before {
            left: calc(var(--container-white-space)*-1);
            width: var(--window-inner-width);
        }
    }
}